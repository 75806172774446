import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";

const Footer = () => {
  const navItems = [
    {
      path: "/",
      label: "Home",
    },
    {
      path: "/opinions",
      label: "Opinie",
    },
    {
      path: "/about",
      label: "O mnie",
    },
    {
      path: "/contact",
      label: "Kontakt",
    },
    {
      path: "/clients",
      label: "Panel Klienta",
    },
    {
      path: "https://www.facebook.com/fotobello",
      label: "Facebook",
    },
    {
      path: "https://www.instagram.com/fotobello_natalia_kalinowska",
      label: "Instagram",
    },
  ];

  return (
    <footer id="footer" className="p-5">
      <Nav className="d-flex justify-content-center">
        {navItems.map((item) => (
          <NavItem>
            <NavLink href={item.path}>{item.label}</NavLink>
          </NavItem>
        ))}
      </Nav>
    </footer>
  );
};

export default Footer;
