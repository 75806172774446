import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/global.scss";
import NavbarComponent from "./navbar";
import { ParallaxProvider } from "react-scroll-parallax";
import Footer from "./foot";
import MenuIcon from "./MenuIcon";
import { NavItem, NavLink } from "reactstrap";
import Link from "gatsby-link";

const Layout = ({
  className = "",
  backgroundColor = "black",
  children,
  caps = false,
}) => {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);

  const navItems = [
    {
      path: "/categories",
      label: "Objerzyj portfolio",
    },
    {
      path: "/opinions",
      label: "Opinie",
    },
    {
      path: "/about",
      label: "O mnie",
    },
    {
      path: "/contact",
      label: "Kontakt",
    },
    {
      path: "/clients",
      label: "Panel Klienta",
    },
    {
      path: "https://www.facebook.com/fotobello",
      label: "Facebook",
    },
    {
      path: "https://www.instagram.com/fotobello_natalia_kalinowska",
      label: "Instagram",
    },
  ];

  return (
    <ParallaxProvider>
      <div className="menu-wrapper" />
      <MenuIcon
        isOpen={open}
        menuClicked={toggle}
        className={`menu-icon ${open && "active"}`}
        width={18 * 2}
        height={15 * 2}
        strokeWidth={2}
        rotate={0}
        color="white"
        borderRadius={0}
        animationDuration={0.5}
      />
      <h1 className={`menu-title ${open && "active"}`}>
        {caps ? <>@CzapkiNatki</> : <img src="/logoWhite.png" />}
      </h1>
      <div className={`menu ${open && "active"}`}>
        <ul id="list-menus">
          {navItems.map((item) => (
            <NavItem className='p-3'>
              <Link href={item.path}>{item.label}</Link>
            </NavItem>
          ))}
        </ul>
      </div>
      <NavbarComponent caps={caps} />
      <main className={className} style={{ backgroundColor }} id="main">
        {children}
      </main>
      <Footer />
    </ParallaxProvider>
  );
};

export default Layout;
