import React from "react";
import { Nav, Navbar, NavbarBrand, NavItem, NavLink } from "reactstrap";

const NavbarComponent = ({ caps }) => {
  const navItems = [
    {
      path: "/",
      label: "Home",
    },
    {
      path: "/opinions",
      label: "Opinie",
    },
    {
      path: "/about",
      label: "O mnie",
    },
    {
      path: "/contact",
      label: "Kontakt",
    },
    {
      path: "/clients",
      label: "Panel Klienta",
    },
    {
      path: "https://www.facebook.com/fotobello",
      label: "Facebook",
    },
    {
      path: "https://www.instagram.com/fotobello_natalia_kalinowska",
      label: "Instagram",
    },
  ];

  return (
    <Navbar id="navbar">
      {caps ? (
        <h1 style={{ color: "white" }}>@CzapkiNatki</h1>
      ) : (
        <NavbarBrand href="/" className="mr-auto">
          <img className="logo" alt="logo" src="/logoWhite.png" />
        </NavbarBrand>
      )}
      <Nav>
        {navItems.map((item) => (
          <NavItem>
            <NavLink href={item.path}>{item.label}</NavLink>
          </NavItem>
        ))}
      </Nav>
    </Navbar>
  );
};

export default NavbarComponent;
